import { Ref, ref } from 'vue';
import axios, { AxiosResponse } from "axios";


export default class Solr {
    isSearchOpen: Ref<boolean>;

    constructor() {
        this.isSearchOpen = ref(false);
    }

    getSuggestions(serviceUrl: string, noResults: string) {
        return (query: string, callback: Function) => {
            axios.get(serviceUrl, {
                params: {
                    'tx_solr[queryString]': query,
                    'tx_solr[callback]': ''         // SOLR needs this callback to work
                }
            }).then((response: AxiosResponse) => {
                let results;
                let data;

                if (response.status !== 200 || response.data.status == false) {
                    return callback([{link: ''}]);
                }
                data = response.data;
                results = data.documents;
                callback(results);
            });
        };
    }
}
