
import {defineComponent, onMounted, ref, inject, provide, watch} from 'vue';
import {ElAutocomplete} from "element-plus";
import Solr from './Solr';
import InlineSvg from 'vue-inline-svg';

export default defineComponent({
    components: {
        ElAutocomplete,
        InlineSvg
    },
    props: {
        serviceUrl: {
            type: String,
            required: true
        },
        formAction: {
            type: String,
            required: true
        },
        label: {
            type: String,
        },
        noResultsText: {
            type: String,
            default: ''
        }
    },
    setup(props) {
        const state = ref(''),
            label = ref(props.label),
            root = ref<HTMLDivElement>(),
            noResults = ref(props.noResultsText),
            serviceUrl = ref(props.serviceUrl),
            formAction = ref(props.formAction),
            solr: Solr | undefined = inject('solr');

        if (!solr) {
            return;
        }

        watch(solr.isSearchOpen, (newValue) => {
            if (newValue) {
                const searchInput: HTMLInputElement | null | undefined = root.value?.querySelector('.el-input__inner');
                window.setTimeout(function () {
                    searchInput?.focus();
                }, 500);
            }
        })
        return {
            solr, state, serviceUrl, formAction, label, noResults, root
        };
    },
})
